<template>
    <v-container :class='["home_info_container text-center",open_search ? "search":""]'>
        <h1>{{$t('home_infos.subtitle')}}</h1>
        <v-btn
            text
            color="primary"
            class='back_btn'
            @click="open_search=false;$emit('click')"
        >
            <v-icon class='mr-3 ml-0'>mdi-arrow-left</v-icon>{{$t('home_infos.moreinfo')}}
        </v-btn>
        <div
            :class="['app_infos',open_search ? 'hide' : '']"
            ref="app_infos"
            id="app_infos"
        >
            <v-btn
                color="secondary"
                class=' pa-7 mt-5 mb-7 geo_btn'
                large
                @click="open_search=true;$emit('click')"
            >
                <v-icon class='mr-3 geo_btn_icon'>mdi-crosshairs-gps</v-icon> {{$t('home_infos.findaproduct')}}
            </v-btn>
            <router-link to="/tutorial">
              <a href=""
                 to='/tutorial'>
                <figure>
                  <v-img
                      :src=homeImgUrl
                      id="central_img"
                  />
                  <figcaption>Comment ça marche ? <v-icon class='ml-6 mr-3 geo_btn_icon'>mdi-chevron-double-right</v-icon> </figcaption>
                </figure>
              </a>
            </router-link>
      </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import {from_header} from "@/main";
/*
@desc First component displayed in Home for app infos
*/
export default {
  data: () => ({
      open_search: false,
      homeImgUrl:""
  }),
  watch: {
      /*
      @desc Change back Illus state when info display state changes
      */
      'open_search': {
          handler() {
              if (from_header === true) this.open_search = true
              if (!this.open_search) this.set_back_state('show_infos')
          },
          immediate: true
      }
  },
  methods: {
    ...mapActions(['set_back_state']),
    from_header() {
      return from_header
    }
  },
  mounted() {
      const height = Math.max(this.$refs.app_infos.offsetHeight, window.innerHeight - 300)
      this.$refs.app_infos.style.height = height + 'px'

      if (localStorage.homeImgUrl) {
        this.homeImgUrl = localStorage.homeImgUrl;
      }
      else {
        this.homeImgUrl = "/images/illus/station.jpg"
        localStorage.homeImgUrl = "/images/illus/station.jpg"
      }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap%22");

.app_infos {
  overflow: hidden;
  width: 100%;
  transition: all 0.5s;
}
.back_btn {
  transition: all 0.5s;
  height: 0px !important;
  overflow: hidden;
}
.search .back_btn {
  height: 36px !important;
}
.app_infos.hide {
  height: 0px !important;
}

.home_info_container {
  width: 800px;
  max-width: 100%;
}
.home_info_container h1 {
  font-family: Roboto,"Playfair Display", arial, helvetica;
  overflow: hidden;
  font-size:2vw !important;
  transition: all 0.5s;
}
.mobile .home_info_container h1 {
  margin-top: 30px;
  font-size:6vw !important;
  height: 130px;
}
.home_info_container.search h1 {
  margin-top: -20px;
}
.mobile .home_info_container.search h1 {
  margin-top: 0px;
  height: 0px;
}

.geo_btn_icon {
  animation: gelatine 1s infinite;
}

#central_img {
  object-fit: cover;
  border-radius: 10px !important;
  height: 633px;
  width: auto;
  box-shadow: 10px 5px 5px rgb(0 0 0 / 10%);
  transition: transform 1000ms;
}

.mobile #central_img {
  height: 35% !important;
}

figure {
  position: relative;
  overflow: hidden;
  border-radius: 10px !important;
  margin-top:30px;
}

.mobile figure {
  margin-top:40px;
}

figure::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: rgba(0, 0, 0, 0.4);
  transform-origin: center;
  opacity: 0;
  transform: scale(2);
  transition: opacity 400ms;
  border-radius: 10px !important;
}

figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  font-weight: bold;
  justify-content: right;
  place-items: center;
  text-align: center;
  padding: 1rem;
  color: white;
  font-size: 2rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 600ms, transform 600ms;
  margin-top:30px;
}

.mobile figcaption {
  font-size: 1.5rem;
  margin-top:40px;
}

a:is(:hover, :focus) figure::after {
  opacity: 1;
}

a:is(:hover, :focus) figcaption {
  opacity: 1;
  transition: opacity 600ms;
}

@media (prefers-reduced-motion: no-preference) {
  figcaption {
    transform: translate3d(0, 2rem, 0);
  }

  figure::after {
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    transition: transform 900ms;
  }

  a:is(:hover, :focus) figure::after {
    transform: scale(2.5);
  }

  a:is(:hover, :focus) figcaption {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 600ms 400ms, transform 600ms 400ms;
  }

  a:is(:hover, :focus) img {
    transform: scale(1.2);
  }
}

@keyframes gelatine {
  from,
  50%,
  to {
    transform: scale(1, 1);
  }
  12% {
    transform: scale(0.8, 1.2);
  }
  25% {
    transform: scale(1.2, 0.8);
  }
  37% {
    transform: scale(0.95, 1.05);
  }
}
</style>