<template>
  <v-dialog
      v-model="display"
      max-width="700px"
      :fullscreen="$is_mobile"
      transition="dialog-bottom-transition"
      v-if="dates.length > 0"
  >
    <!-- ----------------------------------------------------- TEXT FIELD -->
    <template v-slot:activator="{ on }">
      <v-text-field
          :background-color="backgroundColor"
          :label="label"
          :disabled="disabled"
          :dense="dense"
          prepend-inner-icon="mdi-calendar"
          outlined
          readonly
          v-on="on"
          :value="final_dates_display"
      ></v-text-field>
    </template>
    <!-- ----------------------------------------------------- DIALOG -->

    <v-card class='pa-5 text-center'>
      <v-card-title class='justify-center mb-5'>
        <!-- <span v-if="!$is_mobile">Réserver pour</span> -->
        <from-to
            :from="start_date"
            :to="end_date"
            :vertical="$is_mobile"
            :dense="$is_mobile"
        ></from-to>
      </v-card-title>

      <!-- ----------------------------- date picker -->
      <v-date-picker
          :no-title="$is_mobile"
          v-if="is_mids"
          class='dater'
          v-model="dates[0]"
          color="primary"
          :locale="$i18n.locale"
          first-day-of-week="1"
          elevation="5"
          :allowed-dates="allowed_dates"
      ></v-date-picker>
      <v-date-picker
          :no-title="$is_mobile"
          v-else
          class='dater'
          v-model="dates"
          range
          color="primary"
          :locale="$i18n.locale"
          first-day-of-week="1"
          elevation="5"
          :allowed-dates="allowed_dates"
      ></v-date-picker>
      <!-- ----------------------------- timer sliders -->
      <div :class='["time_slider text-left mt-7",$is_mobile ? "mx-5" : ""]'>
        <!-- ------------------------- MIDS -->
        <div
            v-if="is_mids"
            style="display:flex; flex-wrap: wrap; justify-content: center"
        >
          <!--<v-spacer></v-spacer>-->
          <v-btn
              v-for="(range,index) in hours"
              :key="index"
              class='mx-1'
              color="primary"
              elevation="0"
              :text="!is_mid_range_selected(range)"
              :disabled="is_passed(range)"
              @click="times = [[range[0],0],[range[1],0],[range[2],0]]"
          >
            {{range[0]}}:00
            <v-icon
                small
                class='mx-2'
            >mdi-arrow-right</v-icon>
            {{range[1]}}:00
          </v-btn>
          <!--<v-spacer></v-spacer>-->
        </div>
        <!-- ------------------------- multi date -->
        <v-row
            class='ma-0'
            v-else
        >
          <v-col
              class='ma-0 pa-0'
              :cols="$is_mobile && is_date_range ? 12 : 5"
              style="display:flex"
          >
            <v-spacer></v-spacer>
            <time-selecter
                v-model="times[0]"
                :title="is_date_range ? display_dates[0] : null"
                :hours="allowed_blocks[0].hours"
                :minutes="allowed_blocks[0].minutes"
            >
            </time-selecter>
            <v-spacer v-if="$is_mobile && is_date_range"></v-spacer>
          </v-col>
          <v-col
              :cols="$is_mobile && is_date_range ? 12 : 2"
              style="display:flex"
          >
            <v-spacer></v-spacer>
            <v-icon
                class="mr-3 ml-3"
                small
            >mdi-arrow-{{$is_mobile && is_date_range ? 'down' : 'right'}}</v-icon>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
              class='ma-0 pa-0'
              :cols="$is_mobile && is_date_range ? 12 : 5"
              style="display:flex"
          >
            <v-spacer v-if="$is_mobile && is_date_range"></v-spacer>
            <time-selecter
                v-model="times[1]"
                :title="is_date_range ? display_dates[1] : null"
                :hours="allowed_blocks[1].hours"
                :minutes="allowed_blocks[1].minutes"
                :last="true"
            >
            </time-selecter>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class='mt-10'>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="cancel"
        >{{$t('cancel')}}</v-btn>
        <v-btn
            color="primary"
            @click="validate"
        >{{$t('validate')}}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns'
import FromTo from './from-to.vue'
import TimeSelecter from './time-selecter.vue'
/*
@desc Date Time selection system featuring 4 time modes: mids, jumps, hours, quarters
*/
export default {
  props: ['value', 'day_hours', 'background-color', 'label', 'dense', 'disabled', 'time_mode'],
  components: { FromTo, TimeSelecter },
  data: () => ({
    display: true,

    dates: [],
    times: [[9, 0], [19, 0]],

    final_dates_display: '',

    start_hour: 0,
    end_hour: 23,

    modes: ['mids', 'jumps', 'hours', 'quarters'],
    test_mode: 'quarters'

  }),
  watch: {
    dates: {
      handler() {
        if (this.dates[0] === this.dates[1]) this.dates.splice(0, 1)
      },
      deep: true
    },
    'times[0]': {
      handler(newValue) {
        if (!newValue || !Array.isArray(newValue) || newValue.length < 2) return;

        const startHour = newValue[0];
        const startMinute = newValue[1];

        if (
            Array.isArray(this.times[1]) &&
            this.times[1].length >= 2 &&
            (this.times[1][0] <= startHour && this.times[1][1] === 0)
        ) {
          const availableEndHours = this.allowed_blocks[1].hours.filter(
              hour => hour > startHour || (hour === startHour && startMinute < 60)
          );

          if (availableEndHours.length > 0) {
            this.times[1] = [availableEndHours[0], 0]; // Mise à jour de l'heure de fin
          }
        }
      },
      immediate: true,
    },
    'times[1]': {
      handler(newValue) {
        if (!newValue || !Array.isArray(newValue) || newValue.length < 2) return;

        const endHour = newValue[0];
        const endMinute = newValue[1];

        if (
            Array.isArray(this.times[0]) &&
            this.times[0].length >= 2 &&
            (endHour < this.times[0][0] || (endHour === this.times[0][0] && endMinute < this.times[0][1]))
        ) {
          this.times[1] = [this.times[0][0] + 1, 0]; // Ajuster l'heure de fin
        }
      },
      immediate: true,
    }
  },
  computed: {
    is_mids() {
      return this.time_mode == 'mids'
    },
    selectable_blocks() {
      const now = this.now(); // Heure actuelle
      const start_hour = this.start_hour; // Heure de début configurable
      const end_hour = this.end_hour; // Heure de fin configurable

      // Les heures disponibles pour le jour de début
      const day_start_hours = Array(end_hour - start_hour + 1)
          .fill(0)
          .map((_, i) => i + start_hour)
          .filter(h => {
            // Limite les heures pour aujourd'hui à celles >= l'heure actuelle
            return this.dates[0] === format(now, 'yyyy-MM-dd') ? h >= now.getHours() : true;
          });

      // Les heures disponibles pour les jours ultérieurs
      const full_day_hours = Array(end_hour - start_hour + 1)
          .fill(0)
          .map((_, i) => i + start_hour); // Toutes les heures sans restriction

      // Déterminer les heures selon le mode de réservation
      const hours = {
        mids: () => [
          [start_hour, end_hour],
          [start_hour, 14],
          [12, end_hour],
        ],
        jumps: () => [start_hour, 12, 13, end_hour].map(h => h % 24),
        hours: () => {
          if (this.dates.length > 1) {
            // Cas multi-dates : toujours afficher toutes les heures pour les jours ultérieurs
            return this.dates[0] === this.dates[1] ? day_start_hours : full_day_hours;
          }
          return day_start_hours;
        },
        quarters: () => {
          const minutes = [0, 15, 30, 45];
          const nowMinutes = now.getMinutes();
          const currentQuarter = Math.floor(nowMinutes / 15) * 15;
          return this.dates[0] === format(now, 'yyyy-MM-dd') ?
              day_start_hours.map(h => ({ h, minutes: minutes.filter(m => m >= currentQuarter) })) :
              full_day_hours.map(h => ({ h, minutes }));
        },
      }[this.time_mode ?? 'jumps']();

      const minutes = {
        mids: () => [],
        jumps: () => [0],
        hours: () => [0],
        quarters: () => [0, 15, 30, 45],
      }[this.time_mode ?? 'jumps']();

      return { hours, minutes };
    },

    allowed_blocks() {
      console.log("selectable_blocks:", this.selectable_blocks);
      const now = this.now(); // Heure actuelle
      now.setMinutes(0, 0, 0); // Réinitialiser les minutes, secondes, millisecondes

      const blocks = this.selectable_blocks; // Blocs horaires configurés
      const first_datetime = new Date(this.dates[0]); // Date de début
      first_datetime.setHours(...this.times[0]); // Heure de début sélectionnée

      const dates = this.two_dates.sort((d1, d2) => new Date(d1) - new Date(d2)); // Tri des dates

      const allowed_blocks = dates.map((dstr, i) => {
        const date = new Date(dstr);

        if (isNaN(date.getTime())) {
          console.error("Invalid date:", dstr);
          return null;
        }

        let hours = blocks.hours.filter((h) => {
          const d = new Date(date);
          d.setHours(h, 0, 0, 0);

          if (i === 0) {
            // Jour de début (aujourd'hui ou autre jour)
            return d >= now;
          }

          // Jours futurs (i > 0)
          return true;
        });

        // Cas spécifique : jour de fin (i === 1)
        if (i === 1) {
          const is_same_day_as_start = date.toDateString() === first_datetime.toDateString();
          if (is_same_day_as_start) {
            // Si le jour de fin est le même que celui de début
            hours = hours.filter(hour => hour > this.times[0][0]); // Heures après l'heure de début
          } else {
            // Jours futurs : toutes les heures disponibles
            hours = blocks.hours;
          }
        }

        return { ...blocks, hours, last: i === 1 };
      }).filter(block => block !== null); // Supprimer les blocs invalides

      return allowed_blocks;
    },

    hours() {
      return this.selectable_blocks.hours
    },
    two_dates() {
      return this.is_date_range ? this.dates : [this.dates[0], this.dates[0]]
    },
    is_date_range() {
      return this.dates.length == 2
    },
    full_dates() {
      return this.two_dates.map((d, i) => {
        const time = this.times[i]
        const date_obj = new Date(d)
        date_obj.setHours(...time)
        return date_obj
      })
    },
    working_dates() {
      return this.full_dates.sort((d1, d2) => {
        return this.$utils.check_date(d1) - this.$utils.check_date(d2)
      })
    },
    display_dates() {
      return this.working_dates.map(d => format(this.$utils.check_date(d), 'dd/MM/yyyy'))
    },
    final_dates() {
      return this.working_dates.map((d, i) => {
        const time = this.times[i]
        const date_obj = new Date(d)
        date_obj.setHours(...time)
        return date_obj
      })
    },
    start_date() {
      return this.final_dates[0]
    },
    end_date() {
      return this.final_dates[1]
    },
  },
  methods: {
    now() {
      const now = new Date()
      return now
    },
    is_mid_range_selected(range) {
      return range.reduce((a, h, i) => a && h == this.times[i][0], true)
    },
    update_date_display() {
      this.final_dates_display = `${this.$utils.format(this.start_date)} ${this.$t('time-filter.and')} ${this.$utils.format(this.end_date)}`
    },
    allowed_dates(date_str) {
      const date = parse(date_str, 'yyyy-MM-dd', this.now())
      date.setHours(0, 0, 0, 0)
      const start_date = this.now()
      start_date.setHours(0, 0, 0, 0)
      return date >= start_date && (date > start_date || this.now().getHours() < this.end_hour - 1)
    },
    cancel() {
      this.display = false
    },
    validate() {
      this.display = false
      this.update_date_display()
      this.$emit('input', this.final_dates)
    },
    is_passed(range){
      const now = this.now()
      const first_datetime = new Date(this.dates[0])
      const end_hour = range[1]
      first_datetime.setHours(end_hour, 0,0 ,0)
      if (first_datetime <= now) return true
      else return false
    },
  },

  mounted() {

    if (this.value.length > 0 && this.value[0]) {

      const d1 = format(this.value[0], 'yyyy-MM-dd')
      const d2 = format(this.value[1], 'yyyy-MM-dd')

      const t1 = format(this.value[0], 'HH:mm').split(':').map(e => parseInt(e))
      const t2 = format(this.value[1], 'HH:mm').split(':').map(e => parseInt(e))

      this.dates.push(...[d1, d2])
      this.times[0] = t1
      this.times[1] = t2
      this.update_date_display()

    } else {

      const today = this.now()
      if (!this.allowed_dates(format(today, 'yyy-MM-dd')))
        today.setDate(today.getDate() + 1)
      const today_str = format(today, 'yyyy-MM-dd')
      this.dates.push(today_str)


      if (this.is_mids) {
        this.times = this.hours[0].map(h => [h, 0])
      } else {
        this.times[0] = [this.allowed_blocks[0].hours[0], 0]
        this.times[1] = [[...this.allowed_blocks[1].hours].pop(), 0]
      }
    }

  }
}
</script>

<style>
.dater {
  margin-left: auto;
  margin-right: auto;
}
</style>
